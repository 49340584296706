@tailwind base;
@tailwind components;
@tailwind utilities;

.json-view {
  max-width: 100%;
}

.json-view--string {
  word-wrap: break-word;
}

textarea {
  resize: none;
}

/* @layer utilities {
  @variants responsive {
    
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    
    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none; 
    }
  }
} */

@font-face {
  font-family: Roobert;
  src: url("../public/fonts/Roobert-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roobert;
  src: url("../public/fonts/Roobert-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Roobert;
  src: url("../public/fonts/Roobert-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

:root,
code,
kbd,
samp,
pre {
  font-family: Roobert, "Montserrat", sans-serif;
}

/* html {
  font-family: "Montserrat", sans-serif;
} */

/* @media (prefers-reduced-motion: no-preference) {

}
 */

:root {
  --accent: theme("colors.accent");
  --input-background: theme("colors.input-background");

  /* 
    Legacy colors
  */
  --brand-white: #fefff9;
  --brand-orange: #ffa93d;
  --brand-orange-faded: #ffa93d80;
  --text-primary: #333;

  --bg-base-layer: #f7f7f7;
  --bg-primary: var(--brand-white);
  --brand-light-blue: #72cbd6;
  --palette-green-dark: #103236;
  --palette-green-light: #314a4e;
  --focus-main: #44d1e4;
}

body {
  display: flex;
  overflow: hidden;
}

body,
#root {
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

_::-webkit-full-page-media,
_:future,
:root #root,
:root body {
  height: stretch;
}

#root {
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  width: 100vw;
  color: var(--text-primary);
  background: var(--bg-primary);
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  #root {
    grid-template-rows: min-content min-content minmax(0, 1fr);
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

dialog::backdrop {
  background: #ffffffbb;
  animation: showDialog 0.5s ease normal;
  padding: 0;
}

dialog {
  background: transparent;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  padding: 0;
}
dialog:modal {
  max-width: 100vw;
  max-height: 100vh;
  height: 100vw;
  width: 100vh;
  margin: 0;
  padding: 0;
}

dialog[open] {
  animation: showDialog 0.5s ease normal;
}
@keyframes showDialog {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 
  Gradients copied from AdobeXD
*/
/* .sign-in-gradient {
  background: linear-gradient(270deg, #324b4f00, #324b4f);
}
 */

.form-breadcrumb-item::after {
  content: "";
  position: absolute;
  top: 100%;
  height: 4px;
  border-radius: 2px;

  width: 90%;
  background: #314a4e60;
}

.form-breadcrumb-item.active::after {
  background: #314a4e;
}

.form-breadcrumb-item.active {
  color: #314a4e;
  font-weight: bold;
}

.form-breadcrumb-item:not(.active) {
  color: #314a4eaa;
}

.scroll-indicator {
  /* box-shadow: inset 0px 0px 15px -13px rgba(0, 0, 0, 0.8); */

  --top-shadow: inset 0 10px 20px -16px rgba(0, 0, 0, 0.8);
  --bottom-shadow: inset 0 -10px 20px -16px rgba(0, 0, 0, 0.8);
}

.scroll-indicator-not-at-top {
  box-shadow: var(--top-shadow);
}
.scroll-indicator-not-at-bottom {
  box-shadow: var(--bottom-shadow);
}
.scroll-indicator-not-at-bottom.scroll-indicator-not-at-top {
  box-shadow: var(--bottom-shadow), var(--top-shadow);
}

/* 
  Booking suggestions
*/

.booking-suggestion-timeline {
  min-height: 100px;
  /* border: 1px solid gray; */
  border-radius: 0.5rem;

  background: linear-gradient(
    to bottom,
    transparent 49%,
    #00000080 49%,
    #00000080 51%,
    transparent 51%
  );
}

/* .blockquote-with-quotation-marks {
  position: relative;
  padding: 1rem;
  padding-left: 1.5rem;
  margin: 0;
}

.blockquote-with-quotation-marks::before {
  content: "“";
  position: absolute;
  left: 0;
  top: 10%;
  font-size: 3rem;
  line-height: 0.5;
  color: #314a4e;
}

.blockquote-with-quotation-marks::after {
  content: "”";
  position: absolute;
  right: 0;
  top: 90%;
  font-size: 3rem;
  line-height: 0.5;
  color: #314a4e;
} */

.skeleton {
  /* 
  
  #f7f7f7*/
  background: linear-gradient(
    to right,
    #ecebeb 0%,
    #ecebeb 50%,
    #f7f7f7 50%,
    #f7f7f7 100%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.8s ease infinite;
}

/* .skeleton.skeleton-bottom {
  background-size: 200% 10%;
} */

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -100% 50%;
  }
}
